<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(update)">
        <b-row>
          <b-col lg="3">
            <b-row>
              <b-col md="12">
                <iq-card>
                  <template v-slot:body>
                    <b-form-group class="row justify-content-center">
                      <b-avatar
                        rounded
                        square
                        :src="preview"
                        size="200"
                      ></b-avatar>
                      <div class="add-img-user text-center" v-if="typeForm">
                        <input type="hidden" />
                        <div class="p-image">
                          <b-button
                            variant="none"
                            block
                            class="
                              upload-button
                              iq-bg-primary
                              position-relative
                            "
                          >
                            <input
                              type="file"
                              @change="previewImage"
                              class="position-absolute btn-block"
                              accept="image/*"
                              style="opacity: 0"
                            />
                            CARGAR IMAGEN
                          </b-button>
                        </div>
                      </div>
                    </b-form-group>
                    <div class="row justify-content-center" v-if="!typeForm">
                      <b-button
                        class="m-1"
                        variant="warning"
                        :to="{ name: 'users.edit', params: { id: idKey } }"
                        ><i class="ri-edit-2-line"></i> Editar</b-button
                      >
                      <b-button
                        class="m-1"
                        variant="danger"
                        @click="removeUser()"
                        ><i class="ri-delete-bin-6-line"></i> Eliminar</b-button
                      >
                    </div>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="9">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ title }}</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row :class="!typeForm ? 'mb-3' : ''">
                    <i v-if="!typeForm" class="ri-user-3-line h1 ml-3"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-3' : 'col-md-4'"
                      label="Nombres"
                      label-for="name"
                    >
                      <ValidationProvider
                        v-if="typeForm"
                        name="Nombres"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.firstname"
                          type="text"
                          placeholder="Ingrese sus nombres"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                      <h5 v-if="!typeForm">{{ user.firstname }}</h5>
                    </b-form-group>
                    <!-- LASTNAME -->
                    <i v-if="!typeForm" class="ri-user-2-line h1 ml-3"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-3' : 'col-md-4'"
                      label="Apellidos"
                      label-for="lastname"
                    >
                      <ValidationProvider
                        v-if="typeForm"
                        name="Apellidos"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.lastname"
                          type="text"
                          placeholder="Ingrese sus apellidos"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                      <h5 v-if="!typeForm">{{ user.lastname }}</h5>
                    </b-form-group>
                    <!-- RUT -->
                    <i v-if="!typeForm" class="ri-file-user-line ml-3 h1"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-3' : 'col-md-4'"
                      label="RUT"
                      label-for="rut"
                    >
                      <ValidationProvider
                        v-if="typeForm"
                        name="rut"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.rut"
                          :value="validate(user.rut)"
                          type="text"
                          placeholder="RUT"
                          :class="
                            errors.length > 0 || validateRut == false
                              ? ' is-invalid'
                              : ''
                          "
                          v-mask="['########-X', '#######-X']"
                        ></b-form-input>
                        <small class="text-danger" v-if="validateRut == false"
                          >RUT invalido</small
                        >
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                      <h5 v-if="!typeForm">{{ user.rut }}</h5>
                    </b-form-group>
                  </b-row>
                  <b-row :class="!typeForm ? 'mb-3' : ''">
                    <!-- TIPO DE USUARIO -->
                    <i v-if="!typeForm" class="ri-door-lock-line ml-3 h1"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-3' : 'col-md-4'"
                      label-for="comuna"
                      label="Tipo de usuario"
                    >
                      <v-select
                        v-if="typeForm"
                        v-model="user.user_type"
                        placeholder="Seleccione un tipo de usuario..."
                        label="text"
                        :options="users_type"
                      >
                        <template #no-options="{}">
                          Sin Resultados...
                        </template>
                      </v-select>
                      <h5 v-if="!typeForm">{{ user.user_type }}</h5>
                    </b-form-group>

                    <!-- EMAIL -->
                    <i v-if="!typeForm" class="ri-mail-line ml-3 h1"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-7' : 'col-md-8'"
                      label="Correo Electrónico"
                      label-for="email"
                    >
                      <ValidationProvider
                        v-if="typeForm"
                        name="email"
                        rules="email"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          v-model="user.email"
                          type="text"
                          placeholder="Correo Electrónico"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                      <h5 v-if="!typeForm">{{ user.email }}</h5>
                    </b-form-group>
                  </b-row>
                  <b-row :class="!typeForm ? 'mb-3' : ''">
                    <!-- TELEFONO -->
                    <i v-if="!typeForm" class="ri-phone-fill ml-3 h1"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-3' : 'col-md-4'"
                      label="Teléfono"
                      label-for="phone"
                    >
                      <ValidationProvider
                        v-if="typeForm"
                        name="phone"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <vue-tel-input
                          :dropdownOptions="{
                            showFlags: true,
                            width: '230px',
                            tabindex: 2,
                          }"
                          mode="international"
                          :inputOptions="{
                            placeholder: 'Telefono',
                            maxlength: 15,
                            showDialCode: true,
                          }"
                          v-model="user.phone"
                        ></vue-tel-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                      <h5 v-if="!typeForm">{{ user.phone }}</h5>
                    </b-form-group>
                    <!-- COMUNA -->
                    <i v-if="!typeForm" class="ri-earth-line ml-3 h1"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-7' : 'col-md-8'"
                      label-for="comuna"
                      label="Comuna"
                    >
                      <v-select
                        v-if="typeForm"
                        placeholder="Seleccionar..."
                        v-model="user.comuna"
                        label="name"
                        :options="comunas"
                        id="comunas"
                      >
                      </v-select>
                      <h5 v-if="!typeForm">{{ user.comuna.name }}</h5>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <!-- DIRECCION -->
                    <i v-if="!typeForm" class="ri-map-pin-line ml-4 h1"></i>
                    <b-form-group
                      :class="!typeForm ? 'col-md-11' : 'col-md-12'"
                      label="Dirección"
                      label-for="addres"
                    >
                      <ValidationProvider
                        v-if="typeForm"
                        name="Dirección"
                        rules="min:10"
                        v-slot="{ errors }"
                      >
                        <b-form-textarea
                          placeholder="Ingrese una dirección de ubicación"
                          v-model="user.address"
                          rows="2"
                          max-rows="7"
                          :class="errors.length > 0 ? ' is-invalid' : ''"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                      <p class="formaText" v-if="!typeForm">
                        {{ user.address }}
                      </p>
                    </b-form-group>
                  </b-row>
                  <hr v-if="typeForm" />
                  <b-button v-if="typeForm" variant="primary" type="submit"
                    >Guardar</b-button
                  >
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
      <b-row>
        <b-col md="9" class="ml-auto">
          <div v-can="'users.update.password'">
            <iq-card v-if="typeForm">
              <template v-slot:body>
                <h4 class="mb-2">Actualizar contraseña</h4>
                <b-row>
                  <b-form-group
                    class="col-md-12"
                    label="Contraseña"
                    label-for="Contraseña"
                  >
                    <ValidationProvider
                      name="Contraseña"
                      rules="min:8|confirmed:password_confirmation"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="data.password"
                        type="password"
                        placeholder="Contraseña"
                        :class="errors.length > 0 ? ' is-invalid' : ''"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group
                    class="col-md-12"
                    label="Confirmar contraseña"
                    label-for="Confirmar contraseña"
                  >
                    <ValidationProvider
                      vid="password_confirmation"
                      name="Confirmar contraseña"
                      rules="min:8"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="data.password_confirmation"
                        type="password"
                        placeholder="Confirmar contraseña"
                        :class="errors.length > 0 ? ' is-invalid' : ''"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
                <b-button
                  v-if="typeForm"
                  variant="primary"
                  @click="savePassword()"
                  >Actualizar contraseña</b-button
                >
              </template>
            </iq-card>
          </div>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import verificador from "verificador-rut";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: Number,
    },
    title: String,
    idKey: {
      type: String,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      comunas: [],
      validateRut: true,
      data: {
        id: this.idKey,
        password: "",
        password_confirmation: "",
      },
      user: {
        id: "",
        firstname: "",
        lastname: "",
        rut: "",
        phone: "",
        email: "",
        address: "",
        avatar: "",
        comuna: "",
        user_type: "",
        _method: "PUT",
      },
      default: require("@/assets/images/default.png"),
      preview: "",
      users_type: [
        {
          value: 1,
          text: "Administrativo",
        },
        {
          value: 2,
          text: "Estudiante",
        },
        {
          value: 3,
          text: "Profesor",
        },
      ],
    };
  },
  async mounted() {
    core.index();
    if (this.typeForm) {
      let comunas = await this.$store.dispatch("tool/fetchComunas");
      this.comunas = comunas.map((item) => ({ id: item.id, name: item.name }));
    }

    await this.showUser(this.idKey);
    this.user = this.getUser.info;
    this.user.user_type = this.getUser.user_type;
  },

  methods: {
    ...mapActions({
      showUser: "user/showUser",
      destroyUsers: "user/destroyUsers",
      updatePassword: "user/updatePassword",
    }),
    validate(rut) {
      if (verificador(rut)) {
        this.validateRut = true;
      } else {
        this.validateRut = false;
      }
    },
    async update() {
      try {
        this.$store.commit("run");
        const payload = {
          id: this.getUser.id,
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          rut: this.user.rut,
          phone: this.user.phone,
          email: this.user.email,
          address: this.user.address,
          comuna: this.user.comuna.id,
          avatar: this.user.avatar.type ? this.user.avatar : "",
          _method: "PUT",
        };
        await this.$store.dispatch("user/updateUsers", payload);
        this.$router.replace("/users/list");
        this.$store.commit("stop");
        core.showSnackbar("success", "Registro actualizado correctamente");
      } catch (error) {
        this.$store.commit("stop");
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        this.user.avatar = input.files[0];
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async removeUser() {
      this.$store.commit("run");
      try {
        await this.destroyUsers(this.idKey);
        this.$router.replace("/users/list");
        core.showSnackbar("success", "Registro eliminado correctamente");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("stop");
      }
    },
    async savePassword() {
      this.$store.commit("run");
      try {
        await this.updatePassword(this.data);
        this.$router.replace("/users/list");
        core.showSnackbar("success", "Contraseña actualizada correctamente");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("stop");
      }
    },
  },
  computed: {
    ...mapGetters({
      getUser: "user/getUser",
    }),
    typeForm() {
      return this.type === 1 || this.type === 2 ? true : false;
    },
  },
};
</script>
<style>
.form-group label {
  /* display: inline-block; */
  margin-bottom: -1px !important;
}
.formaText {
  color: black;
  font-size: 17px;
}
</style>
